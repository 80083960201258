/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Animation for moving gradient */
.animate-gradient-move {
  background-size: 400%;
  animation: gradient 8s ease infinite;
}

/* src/index.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    position: relative;
    background: white;
    padding: 20px;
    border-radius: 8px;
    outline: none;
    max-width: 80vw;
    max-height: 80vh;
    z-index: 1001;
  }
  